:root {
  --gap: 0.8em;
  --primary-color: #081e30;
}

.loading-page,
.err-401 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.loading-sec {
  background-color: #1976D2;
}

.stats-href {
  width: 100%;
  height: 100%;
}

.center {
  justify-content: center;
  text-align: center;
}


.topm-sec-1,
.topm-sec-2 {
  display: flex;
  justify-content: center;
  width: max-content;
}

.stats {
  border-radius: 0.40rem;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}



.row-total {
  display: flex;
}

.total-paid {
  justify-content: flex-start;
  color: rgb(24, 146, 0);
}

.total-unpaid {
  justify-content: flex-end;
  text-align: end;
  color: rgb(145, 0, 0);
}

.total-paid,
.total-unpaid {
  display: flex;
  font-size: 1.5em;
  font-weight: 600;
  padding: 0.2em;
  margin: 0.2em 0;
  padding-right: 0.5em;
  width: 50%;
}

.tittle-pay {
  font-weight: 600;
}

.stats-title {
  color: white;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
}

.stats-value-panel {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  max-width: 80%;
}

.stats-value-container {
  max-width: 100%;
}

.stats-value {
  font-size: 90%;
  color: #181818;
  max-width: 100%;
  word-wrap: break-word;
}

.stats-title,
.stats-value-container {
  margin-bottom: 0;
  margin-left: 0.8rem;
}

.lbl-login {
  margin-top: 0.8em;
  margin-bottom: 0;
}

.bg-primary {
  background: linear-gradient(180deg, rgba(31, 218, 182, 1) 0%, rgba(25, 179, 149, 1) 51%, rgba(0, 154, 124, 1) 100%);
}

.bg-paid {
  background: linear-gradient(180deg, rgba(103, 219, 90, 1) 0%, rgba(106, 189, 54, 1) 37%, rgba(50, 129, 41, 1) 100%);
}

.bg-total-paid {
  background: linear-gradient(180deg, rgba(9, 67, 130, 1) 0%, rgba(7, 45, 75, 1) 48%, rgba(2, 19, 33, 1) 100%);
}

.bg-near-overdue {
  background: linear-gradient(180deg, rgba(236, 235, 52, 1) 0%, rgba(212, 196, 25, 1) 52%, rgba(185, 169, 0, 1) 100%);
}

.bg-danger {
  background-color: #ac0000;
  background: linear-gradient(180deg, rgba(201, 0, 0, 1) 0%, rgba(185, 0, 0, 1) 58%, rgba(139, 0, 0, 1) 100%);
}

.bg-warning {
  background: linear-gradient(180deg, rgba(255, 87, 32, 1) 0%, rgba(255, 87, 32, 1) 58%, rgba(195, 48, 0, 1) 100%);
}

.bg-wraning-2 {
  background: linear-gradient(180deg, rgba(250, 237, 0, 1) 0%, rgba(205, 212, 0, 1) 52%, rgba(177, 182, 0, 1) 100%);
}

.bg-third {
  background: linear-gradient(180deg, rgba(30, 97, 171, 1) 0%, rgba(15, 67, 108, 1) 52%, rgba(8, 30, 48, 1) 100%);
}

.bg-wg-1{
  background: linear-gradient(180deg, rgba(9, 67, 130, 1) 0%, rgba(7, 45, 75, 1) 48%, rgba(2, 19, 33, 1) 100%);
}

.bg-wg-2{
  background: linear-gradient(180deg, rgba(201, 0, 0, 1) 0%, rgba(185, 0, 0, 1) 58%, rgba(139, 0, 0, 1) 100%);
}

.bg-wg-3{
  background: linear-gradient(180deg, rgba(255, 87, 32, 1) 0%, rgba(255, 87, 32, 1) 58%, rgba(195, 48, 0, 1) 100%);
}

.bg-wg-4{
  background: linear-gradient(180deg, rgba(236, 235, 52, 1) 0%, rgba(212, 196, 25, 1) 52%, rgba(185, 169, 0, 1) 100%);
}

.bg-wg-5{
  background: linear-gradient(180deg, rgba(103, 219, 90, 1) 0%, rgba(106, 189, 54, 1) 37%, rgba(50, 129, 41, 1) 100%);
}

.dashboard-container{
  padding: 2vh;
  row-gap: 2vh !important;
  justify-content: flex-start !important;
  width: 100%;
}

.wg-container{
  border-radius: 1.5vh;
  box-shadow: 0 1px 1px 0 rgba(56, 56, 56, 0.2), 0 6px 20px 0 rgba(90, 90, 90, 0.19);
  /* border: 1px solid #79bb00; */
  display: flex;
  gap: 1vh;
  /* row-gap: 2vh; */
  height: 15vh;
  padding: 2vh;
  color: white;
}

.wg-icon-container{
  width: 16vh;
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wg-icon{
  max-width: 7vh;
  max-height: 7vh;
}

.wg-title{
  font-size: 2.3vh;
  /* font-weight: 600; */
}

.wg-value{
  font-size: 3vh;
  font-weight: 600;
}

.dashboard-stat {
  display: flex;
  align-items: center;
}

.rst_pwd,
.show_payment {
  height: 1.4em;
}

.rst_pwd {
  margin-top: 0.2em;
}

.row-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0.08rem 0;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
}

.row-hist,
.row-div {
  display: flex;
  flex-wrap: wrap;
  padding: 0.15em 0;
  margin: 0;
}

.row-list {
  display: flex;
  width: 100%;
  margin: 0;
}

.hist-sec1 {
  justify-content: flex-start;
}

.hist-sec2 {
  justify-content: flex-end;
}

.hist-sec1,
.hist-sec2 {
  display: flex;
  align-self: center;
}

.cb_label {
  font-size: 0.8em;
  margin: 0;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.row-top-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
  align-items: center;
  row-gap: 2px
}

.row-no-wrap {
  display: flex;
  margin: 0;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
  align-items: center;
  row-gap: 2px
}

.row-flx-alg-top {
  align-items: top;
}

[class*="common_col"] {
  box-sizing: border-box;
}

.col-1 {
  width: 100%;
}

.col-2 {
  width: calc((100%/2) - var(--gap));
}

.col-3 {
  width: calc((100%/3) - var(--gap));
}

.col-4 {
  width: calc((100%/4) - var(--gap));
}

.nr-cl-3 {
  width: calc((100%/3) - var(--gap));
}

.nr-cl-80 {
  width: calc(80% - var(--gap));
}

.nr-cl-20 {
  width: calc(20% - var(--gap));
}

.col-5 {
  width: calc((100%/5) - var(--gap));
}

.col-6 {
  width: calc((100%/6) - var(--gap));
}

.col-7 {
  width: calc((100%/7) - var(--gap));
}

.col-8 {
  width: calc((100%/8) - var(--gap));
}

.col-9 {
  width: calc((100%/9) - var(--gap));
}

.col-75-8 {
  width: calc((75.8%) - var(--gap));
}

.col-70 {
  width: calc((70%) - var(--gap));
}

.col-60 {
  width: calc((60%) - var(--gap));
}

.col-30 {
  width: calc((30%) - var(--gap));
}

.col-35 {
  width: calc((35%) - var(--gap));
}

.col-20 {
  width: calc((20%) - var(--gap));
}

.col-25 {
  width: calc((25%) - var(--gap));
}

.col-40 {
  width: calc((40%) - var(--gap));
}

.col-80 {
  width: calc((80%) - var(--gap));
}

.col-90 {
  width: calc((90%) - var(--gap));
}

.col-10 {
  width: calc((10%) - var(--gap));
}

.colNR-2 {
  width: calc((100%/2) - var(--gap));
}

.vertical-center {
  align-items: center;
}

.stats-img {
  max-width: 32px;
  max-height: 32px;
}

.stats-img-panel {
  width: 5em;
  height: 5em;
  line-height: 5em;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  text-align: center;
  justify-content: center;
}

.txt-white {
  color: white;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

.txt-c {
  text-align: center;
}

.txt-b {
  font-weight: bold;
}

.h-100 {
  min-height: 100%;
  align-items: bottom;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.mtch-check {
  width: 100%;
  padding: 5px 0;
  font-weight: 500 !important;
}

.title-3 {
  font-size: 1em;
  color: rgb(136, 136, 136);
}

.title-2 {
  font-size: 1.1em;
  font-weight: bold;
  color: rgb(81, 81, 81);
}

.card-title {
  margin-right: 0.9em;
  font-weight: 500;
}

.card {
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  width: 100%;
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: #dee2e6;
  --bs-card-border-radius: 0.25rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.25rem - 0);
  --bs-card-cap-padding-y: 0.75rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #e9ecef;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: calc(scut-strip-unit(1.5rem) / 2);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color)
}

.mtb-1 {
  margin: 0.5rem 0;
}

.mtb-2 {
  margin: 0.8rem 0;
}

.table-fix {
  overflow-y: auto;
  max-width: 100%;
  font-size: smaller;
}

.table-fix tbody tr:nth-child(odd) td {
  background-color: #fafafa !important;
}

.table-responsive {
  max-height: 70vh;
  overflow-y: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.table-cash-bank {
  max-height: 60vh;
}

.table {
  --bs-table-color: #1d1d1d;
  --bs-table-bg: transparent;
  --bs-table-border-color: #e9ecef;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #1d1d1d;
  --bs-table-striped-bg: rgb(232, 231, 231);
  --bs-table-active-color: #1d1d1d;
  --bs-table-active-bg: #f8f9fa;
  --bs-table-hover-color: #1d1d1d;
  --bs-table-hover-bg: rgb(211, 237, 255);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.filter-select,
.fltr-select-category {
  margin-left: 0.3em;
}

.thead {
  position: sticky;
  top: 0px;
  z-index: 1;
  color: white;
  overflow-y: auto;
}

.thead tr th {
  background: linear-gradient(0deg, rgba(16, 44, 56, 1) 0%, rgba(17, 63, 83, 1) 49%, rgba(0, 81, 116, 1) 100%);
  color: white;
}

.thead tr th:first-child {
  border-radius: 1em 0 0 0;
}

.thead tr th:last-child {
  border-radius: 0 1em 0 0;
}

.tbody tr td {
  background-color: #ffffff;
}

.thead tr th,
.tbody tr td {
  text-align: center;
}

.flex-start {
  justify-content: flex-start !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.page-item:last-child .page-link {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-link {
  padding: .375rem .75rem;
  position: relative;
  display: block;
  color: #012254;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-top-color: rgb(222, 226, 230);
  border-right-color: rgb(222, 226, 230);
  border-bottom-color: rgb(222, 226, 230);
  border-left-color: rgb(222, 226, 230);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page-item.activePage .page-link {
  z-index: 3;
  color: #fff;
  background-color: #051f46;
  border-color: #01193c;
}

.page-link:hover {
  background-color: #d8d8d8;
  color: #4c71a4;
}

.page-link:focus {
  z-index: 3;
  color: #023075;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.tbl-pagination {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 0.8em 0;
  position: sticky
}

.dv-search {
  width: 100%;
}

.dv-pass label,
.search,
.search input {
  width: 100%;
}

.password {
  position: relative;
  color: #aaa;
  font-size: 16px;
  display: inline-block;
}

.icon-eye {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.icon-eye:hover {
  color: rgb(112, 112, 112);
}

.icon-eye {
  left: auto;
  right: 10px;
}

.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
  display: inline-block;
}

.icon-eye {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon-eye:hover {
  color: rgb(112, 112, 112);
}

.icon-eye {
  left: auto;
  right: 10px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dd {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none;
}

.form-control-file:focus {
  border: 1px solid #4682d1 !important;
  list-style: 1.5;
  max-height: 36px;
}

.captcha-sec div {
  max-height: 35px;
}

.captcha-sec div canvas {
  height: 35px !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 35px !important;
  border: 0 !important;
  background-color: #707070 !important;
  color: white !important;
  font-size: medium !important;
}

.captcha-sec div:nth-child(2) {
  display: none;
  height: 0px !important;
}

.form-control-search {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 1.5em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 2em;
}

.date-picker {
  width: 15.5em;
  height: 2.5em;
}

.range-date-picker {
  height: 100%;
  border-radius: 40em;
  margin: 0 0.4em;
}

.date-picker-tb {
  min-width: 150px;
  max-width: 150px;
  max-height: 35px;
}

.date-picker-tb-m {
  max-height: 35px;
}

/* ant-picker-range-wrapper */

.date-picker-tb-m .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;

  @media (max-width: 768px) {
    display: inline-grid;
  }
}

.period-select {
  min-width: 120px;
  max-width: 120px;
}


.rm-search {
  width: auto;
  /* margin-left: 0.4em; */
}

.f-wrap {
  display: flex;
  flex-wrap: wrap;
}

.left-menu {
  width: 80%
}

.priority-t {
  color: #ecba2f !important;
}

.priority-f {
  color: #dfdfdf !important;
}

.priority-f:hover,
.priority-t:hover {
  color: #ecba2f;
}

.priority-set:hover {
  color: #ffc400;
}

.bank-pay {
  white-space: pre-wrap;
}

.rm-st {
  width: 100%;
  padding: 0 5%;
}

.rm-st-p,
.rm-st-u,
.rm-st-partial {
  width: 100%;
  color: white;
  font-weight: bold;
  padding: 0.2em 0;
  text-align: center;
  font-size: 1.2em;
}

.rm-st-p {
  background-color: #30a73a;
}

.rm-st-u {
  background-color: #c51b1b;
}

.rm-st-partial {
  background-color: #F7B512;
}

.sub-title {
  width: 100%;
  text-align: center;
  color: rgb(190, 190, 190);
  word-wrap: break-word;
  padding: 0 0.8em;
}

.notes-sec {
  width: 100%;
  text-align: center;
  padding: 1em 5%;
}

.pay-hist-sec {
  width: 100%;
  text-align: center;
  padding: 0.2em 5%;
  font-size: 0.9em;
}

.dtl-id-rm {
  width: 100%;
  font-size: 1.1em;
  margin-top: 1em;
}

.dtl-hr {
  width: 100%;
}

.tbl-d {
  width: 100%;
  padding: 0 5%;
}

.tbl-select-stock {
  width: 100%;
  padding: 0 2%;
}

.tbl-dtl {
  width: 100%;
  padding: 0 5%;
  margin-top: 0.4em;
  border-collapse: collapse;
  font-size: 0.9em;
}

.tbl-dtl tbody tr:nth-child(odd),
.olt-tbl tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}


.tbl-dtl tr:hover td,
.olt-tbl tr:hover td {
  background: #d8f3fc !important;
}

.tbl-dtl tbody tr td:nth-child(2) {
  text-align: center;
  width: 2%;
}

.tbl-dtl tbody tr td:first-child {
  width: 48%;
  padding: 0 0.4em;
  color: #474747;
}

.tbl-dtl tbody tr {
  vertical-align: top;
}

.tbl-dtl tbody tr td:last-child {
  width: 100px;
  max-width: 48%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
  text-align: right;
  padding: 0 0.4em;
  color: #707070;
}

.title-data-profile {
  font-weight: 500;
  max-width: 45% !important;
  width: 45% !important;
}

.data-profile {
  padding-left: 2em;
  display: grid;
}

.content-profile {
  display: flex;
  height: fit-content;
}

.value-data-profile {
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 45% !important;
  width: 45% !important;
  word-break: break-all;
}

.v-notes {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
  font-size: 0.9em;
  color: #707070;
}

.file-lists {
  width: 100%;
  padding: 0 5%;
}

.f-ls-itm {
  padding: 0;
  justify-items: center;
  justify-content: center;
}

.s-f-ls {
  width: 100%;
  display: flex;
  background-color: #f7f7f7;
  flex-wrap: wrap;
}

.pay-sec-f,
.up-sec-f {
  width: 50%;
}

.dtl-info {
  width: 100%;
  padding: 0 5%;
  padding-top: 1.5em;
  align-items: baseline;
}

.dtl-info div {
  justify-content: center;
  justify-items: center;
}

.lbl-dlg-ttl-i,
.lbl-dlg-v-i {
  user-select: none;
  font-size: 0.9em;
  margin: 0;
  text-align: center;

}

.lbl-dlg-ttl-i {
  font-weight: 500;
  color: #707070;
}

.lbl-dlg-v-i {
  font-size: 0.6rem;
  color: #acacac;
}

.d-notes,
.rm-f-ls {
  text-align: center;
  font-weight: 500;
}

.rm-f-ls {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: #d8d8d8;
  height: 2em;
  font-size: 0.9em;
  width: 100%;
  color: #707070;
}

.li-item {
  display: flex;
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  padding: 0.3em 0.3em !important;
  font-size: 0.9em;
  justify-content: center;
  justify-items: center;
  color: #9c9c9c;
  align-items: center;
}

.ul-group {
  background-color: #01193c !important;
}

.icon-btn {
  padding: 0.1em;
  font-size: 1.7em;
}

.files-icon {
  margin-right: 4px;
  width: 1.2em !important;
  height: 1.2em !important;
}

.dl-icon {
  color: #70d469;
  width: 1.35em !important;
  height: 1.35em !important;
}

.dl-success-icon {
  color: #70d469;
  width: 1em !important;
  height: 1em !important;
}

.dl-icon:hover,
.dl-success-icon:hover {
  color: #0ecf00;
}

.fl-icon {
  color: #dfa300;
}

.adj-icon {
  color: #14be47;
  font-size: 2em;
  margin-left: 0.3em;
}

.vw-icon {
  transform: scale(1.3);
  margin-left: 3px;
}

.vw-icon:hover {
  color: #3d7ad6
}

.container-img {
  width: 100VW;
  height: 100VH;
}

.priority-icon {
  position: fixed;
  top: 1.35em;
  right: 0.9em;
  z-index: 2000;
  color: #d68b00;
  font-size: 3em;
}

.priority-icon:hover {
  color: #b87700;
}

.btn-img-prv-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  color: #bd1919;
  margin: 0.4em;
  font-size: 1.5em;
}

.btn-img-prv-close:hover {
  color: #eb3030;
}


.btn-select-stock {
  margin-left: 1em;
}

.img-preview {
  max-width: 100%;
  max-height: 100%;


  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.loading-download {
  width: 1em !important;
  height: 1em;
  margin-right: 3px;
}

.li-item-left {
  justify-content: flex-start !important;
}

.dz {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.drop-zone {
  flex-grow: 1;
  font-size: 1.1em !important;
}

.dz-header,
.dz-footer {
  font-size: 0.7em !important;

}

.dz-header span:last-child svg {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 9px rgb(228, 228, 228);
  border-top-width: 0;
  cursor: pointer;
  margin-left: 3px;
}

.up-arrow:hover {
  border-bottom: solid 9px rgb(0, 196, 33);
}

.down-arrow:hover {
  border-top: solid 9px rgb(0, 196, 33);
}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 9px rgb(228, 228, 228);
  border-bottom-width: 0;
  margin-top: 1px;
  cursor: pointer;
  margin-left: 3px;
}

.header-tbl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.7em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hide-el {
  display: none !important;
  pointer-events: none;
}

.site-tree-search-value {
  color: #f50;
}

.site-tree-title {
  font-weight: bold;
  color: #575757;
}

.site-tree-title:hover {
  color: #000000;
}

.site-tree-key-selected {
  color: #000000;
}

.acc_detail {
  display: flex;
  align-items: center;
}

.acc-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tbl-add-journal {
  width: 100%;
  border-spacing: 3px 0;
}

.row-j {
  display: flex;
  height: max-content;
  justify-content: center;
  width: 100%;
}



.btn-add-journal {
  margin: 0;
  height: 35px;
  width: 200px;
}

.mb-0 {
  margin-bottom: 0;
}

.dv-tbl-act {
  display: flex;
  justify-content: center;
}

.tab-coa {
  height: 73vh;
}

.coa-tree {
  max-height: 69vh;
  overflow-y: scroll;
}

.tree-menu {
  position: sticky;
}


.dropdown {
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: 0.3em;
  width: max-content;
  user-select: none;
  margin: 0 0.4em;
}

.dd-button {
  display: flex;
  color: white;
  border: none;
  border-radius: 1.5em;
  padding: 0.375rem 0.8rem;
  background-color: #1976D2;
  cursor: pointer;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  font-size: 13.5px;
  width: max-content;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-left: 8px;
}

.dd-button:hover {
  background-color: #095da1;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 0.7em;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
  z-index: 88;
  width: 11em;
}

.dd-input+.dd-menu {
  display: none;
}

.dd-input:checked+.dd-menu {
  display: block;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:first-child {
  border-radius: 0.7em 0.7em 0 0;
}

.dd-menu li:last-child {
  border-radius: 0 0 0.7em 0.7em
}

.dd-menu li:hover {
  background-color: #daeeff;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.top-dash {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.acc-dash {
  font-size: 1.8em;
  font-weight: 500;
  color: #a1a1a1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 2em;
  max-width: 100%;

}

.back-arrow-icon {
  font-size: 1.8em;
  color: #4682d1;
}

.text-red {
  color: #c41515 !important;
}

.text-green {
  color: lightgreen !important;
}

.text-green-1 {
  color: green !important;
}

.tab-pane {
  padding-top: 0.6em;
}

.import-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.import-container input[type=file]::file-selector-button {
  background-color: rgb(38, 120, 197);
  color: rgb(255, 255, 255);
  border: 0px;
  border-right: 1px solid #e5e5e5;
  border-radius: 4px 0 0 4px;
  padding: 7px 12px;
  margin-right: 20px;
  transition: .5s;
}

.import-container input[type=file]::file-selector-button:hover {
  background-color: #3c84e2;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  margin-bottom: 0 !important;
}

.tbl-import {
  max-height: 50vh;
  overflow: scroll;
}

.thead-import {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tx-wrap-nl {
  word-wrap: break-word;
  hyphens: auto;
}

.flex-top {
  align-items: flex-start;
}

.flex-bottom {
  align-items: flex-end;
}

.ovf-y-s {
  overflow-y: scroll;
}

.ovf-x-s {
  overflow-y: scroll;
}

.div-tbl {
  width: fit-content;
  overflow: scroll;
}

.container-add-rcv {
  overflow: auto;
  overflow-y: hidden;
  overflow-x: scroll;
}

.modal-zoom-root {
  display: flex;
  align-items: center;
}

.modal-input-zoom {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  height: 2.3em;
  width: 100%;


  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
  font-size: 1.8em !important;
  text-align: center;
}

.modal-input-zoom-text {
  height: 6em;
  font-size: 1.4em !important;
}

.txt-no-edit-tbl {
  width: 100%;
}

.footer-tbl-title {
  font-weight: bold;
  font-size: 0.9em;
}

.footer-value {
  font-size: 0.9em;
  max-width: 23rem;
  min-width: 8rem;
  padding-left: 30px;
  overflow: scroll;
  text-overflow: ellipsis;
}

.sec-sum {
  padding-right: 7.45em;
}

.sec-sum-manual-journal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sub-sec-sum-manual-journal {
  width: max-content;
  display: flex;
  flex-direction: row;
}

.sum-credit,
.sum-debit {
  padding: 0 1em;
  min-width: 10em;
  max-width: 15em;
}

.text-ov-el {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sec-sum-pay {
  padding-right: 0.3em;
  font-size: 1rem;
}

.footer-tbl {
  border-top: 1px solid rgb(202, 202, 202);
}

.footer-tbl-total {
  font-size: 1.3em;
  font-weight: bold;
}

.filter-apply-send {
  height: 100%;
}

.t_date {
  height: 2.5em;
}

.row-sum,
.row-sum-pay-exp {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.table-sh-j {
  width: 100%;

}

.tbl-j-div {
  width: 100%;
  overflow-x: scroll;

}

.table-sh-j thead {
  text-align: center;
  background-color: #28425c;
  color: white;
}

.table-sh-j tbody tr td:nth-child(n+5),
.table-sh-j tfoot tr td {
  text-align: right;
}

.table-sh-j tbody tr td:nth-child(n+1):nth-child(-n+2) {
  text-align: center;
}

.table-sh-j tfoot tr td {
  font-size: 1.1em;
  font-weight: 600;
}

.table-sh-j tfoot {
  border-top: 1px solid #929292;
}

.flex {
  display: flex;
}

.text-nowrap {
  white-space: nowrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
  flex-direction: row;
}

.rem-debt {
  font-weight: 500;
  font-size: 1.7rem;
}

.stch-container {
  align-items: stretch !important;
}

.notes-pay {
  flex-direction: column;
}

.tbl-cell-amount {
  text-align: right;
  padding-right: 4px;
}

.exp-top-hr {
  display: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.form-control-wrap {
  position: relative !important;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  width: 1% !important;
  margin-bottom: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  max-height: 35px;
}

.form-control-select-wrap {
  position: relative !important;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  width: 1% !important;
  margin-bottom: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  max-height: 35px;
}

.error_msg_comm {
  margin: 0;
  margin-top: 0.2em;
  color: red;
  font-size: 0.7em;
  line-height: 1em;
  height: 1em;
}

.qrcontainer {
  display: grid;
  grid-template-columns: 110px 135px 55px;
  grid-template-rows: 20px 57px 33px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: fit-content;

  border: 1px solid #c7c7c7;
}

.qrDisplay {
  grid-area: 1 / 1 / 4 / 2;
  max-width: 110px;
  max-height: 110px;
  min-width: 110px;
  min-height: 110px;

}

.qrDisplay div {
  justify-content: center;
}

.binLabelInfo {
  grid-area: 1 / 2 / 3 / 3;
  min-width: 135px;
  max-width: 135px;
  padding: 4px;
  background-color: #A3EB4C;
  color: black;
  overflow: hidden;

}

.binLabelInfoCode {
  font-size: 11px;
  font-weight: 600;
  margin: 0;
}

.binLabelInfoPIName {
  font-size: 8px;
  max-width: 124.17px;
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.binLabelInfoPIID {
  font-weight: 600;
  font-size: 8px;
}

.binLabelInfoPIIDValue {
  font-size: 11px;
}


.binNumberTitle {
  grid-area: 1 / 3 / 2 / 4;
  min-width: 55px;
  max-width: 55px;
  font-weight: bolder;
  font-size: 14px;
  color: black;
}

.binNumberValue {
  grid-area: 2 / 3 / 3 / 4;

  font-weight: bolder;
  text-align: center;
  color: black;
  font-size: 25px;
}

.binCode {
  grid-area: 3 / 2 / 4 / 4;
  width: 190px;
  background-color: black;
  color: white;
  font-size: 11px;
  font-weight: 900;
}

.flx-all-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-bin-label {
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.label-content {
  display: flex;
  justify-content: center;
}

.tbl-dtl-pil {
  width: 100%;
  padding: 0 5%;
  margin-top: 0.4em;
  border-collapse: collapse;
  font-size: 0.9em;
}

.tbl-dtl-pil tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.tbl-dtl-pil tbody tr {
  vertical-align: top;
}

.tbl-dtl-pil-title-body {
  min-width: 100px;
  font-weight: 500;
}

.tbl-dtl-pil tbody tr td:nth-child(2) {
  text-align: center;
  min-width: 10px;
}

.tbl-dtl-pil-value-body {
  text-align: right;
  width: 100%;
}

.tbl-dtl-pil tr:hover td {
  background: #d8f3fc !important;
}

.fnb-list-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 200px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 8px;
  border: 1px solid #c9c9c9;
  box-shadow: 0 4px 8px 0 rgba(56, 56, 56, 0.2), 0 6px 20px 0 rgba(90, 90, 90, 0.19);
  color: #3f3f3f;
}

.fnb-list-content-img {
  object-fit: cover;
  object-position: center;
  height: 170px;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.fnb-list-content-detail {
  padding: 10px;
  height: 130px;
  max-height: 130px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.txt-of-elps {
  overflow: hidden;
  text-overflow: ellipsis;
}

.fnb-list-content-title {
  width: 100%;
  font-weight: 600;
  font-size: 90%;
  height: 20px;
  max-height: 20px;
  white-space: nowrap;
}

.fnb-list-content-desc {
  font-size: 80%;
  color: #707070;
  width: 100%;
  height: 45px;
  max-height: 45px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.fnb-list-content-total {
  margin-top: 4px;
  text-align: right;
  font-size: 1.1em;
  font-weight: 600;
  white-space: nowrap;
}

.fnb-list-content-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.fnb-menu-list {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, 200px);
  justify-content: space-between;
}

.emp-img-container{
  display: flex;
  width: 170px;
  height: 208px;
  border-radius: 7px;
  border: 1px solid #bebebe;
}

.fnb-add-menu-img {
  width: 250px;
  height: 250px;
  border-radius: 7px;
  border: 1px solid #bebebe;
}

.fnb-add-menu-img img,
.emp-img-container img {
  max-width: 100%;
  max-height: 100%;
  width: 250px;
  height: 250px;
  border-radius: 7px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.fnb-add-menu-browse-img, .emp-browse-img {
  align-self: center;
  margin: 7px 0;
}

.fnb-add-menu {
  align-items: flex-start;
}

.fnb-add-menu-container-img,
.emp-img-prvw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr-scan-display-container,
.qr-scan-btn-container,
.qr-scan-result-container {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.olt-title {
  margin-top: 1em;
  font-size: 1em;
  font-weight: 600;
}

.olt-tbl {
  width: 100%;
  cursor: pointer;
}

.olt-tbl tr td,
.olt-tbl tr th {
  text-align: center;
}

.olt-tbl tr td:first-child {
  text-align: left;
}

.olt-tbl tr th {
  background: #012f3f !important;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  padding: 0.5em 0;

}

.olt-tbl tr th:last-child {
  border-radius: 0 0.5em 0 0;
}

.olt-tbl tr th:first-child {
  border-radius: 0.5em 0 0 0;
}

.olt-tbl tr th:nth-child(1) {
  min-width: 30%;
  max-width: 30%;
}

.new-order-bg {
  background-color: #53b611 !important;
  color: white;
}

.olt-tbl tr:hover .new-order-bg {
  background-color: #53b611 !important;
  color: white;
}

.table-fix tbody tr:hover td {
  background-color: rgb(211, 237, 255) !important;
}

.tbl-cell-danger {
  color: white !important;
}

.table-fix tbody tr:hover .tbl-cell-warning {
  background-color: #e9ca1b !important;
}

.table-fix tbody tr:nth-child(2n+1) .tbl-cell-warning {
  background-color: #e9ca1b !important;
}

.tbl-cell-warning {
  background-color: #e9ca1b !important;
}

.tbl-cell-danger,
.table-fix tbody tr:hover .tbl-cell-danger {
  background-color: #a10000 !important;
}

.table-fix tbody tr:nth-child(2n+1) .tbl-cell-danger {
  background-color: #a10000 !important;
}

.expired-section{
  display: flex;
}

.emp-fp-sec{
  padding: 7px 0;
}

.flx-start{
  /* justify-content: flex-start; */
  align-items: flex-start;
}

.img-browse-container-btn{
  position: absolute;
  width: 170px;
  height: 208px;
  background-color: rgb(235, 235, 235, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.emp-img-container:hover .img-browse-container-btn {
  display: flex;
}

@media screen and (max-width: 1240px) and (min-width: 721px) {
  [class*="col-"] {
    width: 100%;
  }

  .expense-top4 {
    width: calc((50%) - var(--gap)) !important;
  }

  .top-rmn-debt {
    display: none;
  }

  .expense-top-to {
    text-align: right;
  }

  .exp-top-hr {
    display: block;
  }

  .expense-top-debt {
    text-align: center;
    justify-content: center;
  }

  .date-picker {
    width: 10em;
  }

  .rm-search {
    width: 10em;
  }

  .stats-value {
    font-size: 1.8em;
  }


}

@media screen and (max-width: 720px) {
  [class*="col-"] {
    width: 100%;
  }

  .rem-debt {
    font-size: 1.3rem;
  }

  .top-rmn-debt {
    display: none;
  }

  .expense-top4 {
    width: calc((50%) - var(--gap)) !important;
  }

  .exp-top-hr {
    display: block;
  }

  .expense-top-debt {
    text-align: center;
    justify-content: center;
  }

  .expense-top-to {
    text-align: right;
  }

  .mdl-info {
    width: 20%;
  }

  .dtl-info {
    padding: 0;
    align-items: baseline;
  }

  .lbl-dlg-ttl-i {
    font-size: 0.75em;
  }

  .row-top-menu {
    justify-content: space-around;
  }

  .total-paid,
  .total-unpaid {
    font-size: 0.9em;
  }

  .file-lists {
    padding: 0;
  }

  .stats-value {
    font-size: 1.5em;
  }

  .tbl-pagination {
    scale: 0.8;
  }

  .pay-hist-sec {
    padding: 0;
  }

  .header-tbl {
    justify-content: start;
  }

  .acc-dash {
    font-size: 1.5em;
  }

  .back-arrow-icon {
    font-size: 1.5em;
  }

  .sec-sum {
    padding-right: 0;
  }

  .row-sum {
    justify-content: center;
  }

  .title-data-profile {
    text-align: right !important;
  }

  .value-data-profile {
    text-align: left !important;
  }

  .thead-select-stock tr th:nth-child(2),
  .thead-select-stock tr th:nth-child(3) {
    display: none;
  }

  .tbody-select-stock tr td:nth-child(1) {
    /* font-size: calc((25vw - 4rem) / 7) */
    font-size: 1.2em;
  }

  .tbody-select-stock tr td:nth-child(2),
  .tbody-select-stock tr td:nth-child(3) {
    display: none;
  }

  .fnb-menu-list {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  .fnb-list-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    min-height: 150px;
    max-height: 150px;
  }

  .fnb-list-content-img {
    min-height: 150px;
    max-height: 150px;
    max-width: 150px;
  }

  .fnb-list-content-detail {
    height: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 600px) {}