/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

.responsiveTable>tbody>:first-child {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

/* .responsiveTable>tbody>:nth-child(1) {
  display: none;
} */

.responsiveTable>tbody>:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
    padding: 0;
  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: 0;
    margin: 0;
    /* padding: .25em; */
    overflow: hidden;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .responsiveTable td.pivoted:first-child{
    padding-top: 10px;
  }
  .responsiveTable td.pivoted:last-child{
    padding-bottom: 1em;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}
