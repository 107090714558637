.navbar-brand-box {
    padding: 0;
    background-color: #102C38;
    height: 70px;
    width: 240px;
    justify-content: center;
    align-items: center;
}

.logo-img-lg {
    height: 55px;
    margin: 0;
    padding: 0;
}

.logo-img-sm {
    height: 30px;
    margin: 0;
    padding: 0;
}

.logo-sm{
    padding: 0;
}