:root {
  --tbl-col1: 50px;
  --tbl-col2: 50px;
  --tbl-col3: 50px;
  --tbl-col4: 50px;
  --tbl-col5: 50px;
  --tbl-col6: 50px;
  --tbl-col7: 50px;
  --tbl-col8: 50px;
  --tbl-col9: 50px;
  --tbl-col10: 50px;
  --tbl-col11: 50px;
}

.title_modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lbl_no_select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.8rem;
  color: #b2b0b0;
  font-weight: 500;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-end {
  text-align: right !important;
}

.ft-1 {
  font-size: 1.8em;
}

.ft-br {
  font-weight: bolder;
}

.tx-c {
  text-align: center;
}

.tx-sp {
  letter-spacing: 6px;
}

.overpaid {
  color: #a56600;
}

.unpaid {
  color: #b90000;
}

.paid {
  color: #00a416;
}

.pd-0 {
  padding: 0;
  margin: 0
}

.sub_title {
  color: var(--N700, rgba(19, 21, 26, 0.96));
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}

.sub_title_payment {
  background-color: var(--N700, rgba(64, 172, 60, 0.96));
  color: white;
  padding: 2px 5px;
  print-color-adjust: exact;
  width: 100%;
  font-weight: 500;
  font-size: 0.9em;
  justify-content: center;
  text-align: center;
}

.invoice_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.invoice_title h4 {
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  color: var(--N700, rgba(49, 53, 59, 0.96));
  display: block;
  position: relative;
  font-weight: 800;
  font-family: "Open Sauce One", -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  font-size: 16px;
  line-height: 22px;
  -webkit-text-decoration: initial;
  text-decoration: initial;
  margin: 0;
}

.lbl_modal {
  font-size: 0.8em;
  margin-bottom: 0;
  color: #717171;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-select-err div {
  border-color: red;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border: 1px solid #2684ff;
}

.form-control::placeholder,
.formfile {
  color: #bebebe;
  opacity: 1;
}

.txt-area {
  resize: none;
  overflow-y: scroll;
}

.error_msg1 {
  /* margin-top: 0.2em; */
  color: red;
  font-size: 0.7em;
  /* line-height: 1em; */
}

.error_msg {
  margin-top: 0.2em;
  color: red;
  font-size: 0.7em;
  line-height: 1em;
}

.form-control-err {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid red;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-err::placeholder,
.formfile {
  color: #bebebe;
  opacity: 1;
}

.chck {
  display: hidden;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.check-trail {
  display: flex;
  align-items: center;
  width: 4em;
  height: 2em;
  background: #e0e0e0;
  border-radius: 2.5em;
  transition: all .5s ease;
  cursor: pointer;
}

.check-handler {
  display: flex;
  margin-left: 0.3em;
  justify-content: center;
  align-items: center;
  width: 1.6em;
  height: 1.6em;
  background: #eb4949;
  border-radius: 50%;
  transition: all .5s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.check-handler::before {
  content: "\00D7";
  color: white;
  font-size: 1em;
  font-weight: bold;
}

.chck:checked+.check-trail {
  background: #e0e0e0;
}

.chck:checked+.check-trail .check-handler {
  width: 1.6em;
  height: 1.6em;
  transform: translateX(113%);
  background: #0ab08f;
}

.chck:checked+.check-trail .check-handler::before {
  content: "\2714"
}

.lbl_key_edit {
  color: #4b4b4b;
  font-weight: 300;
  background-color: rgb(217, 236, 207);
  word-wrap: break-word;
}

.expid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sl_1st {
  color: #e3e3e3;
}

.act_task_modal {
  width: auto;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 1.5em;
  font-size: 0.7rem;
}

.tbl_desc {
  word-wrap: break-word
}

.list_item {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}

.list_item-empty {
  display: flex;
  justify-content: center !important;
  width: 100%;
}

.list_item-empty p {
  margin-bottom: 0.1em;
}

.list_item:hover {
  background-color: #e7f3ff;
}

.list-paid {
  font-weight: 500;
  color: #00b627;
}

.list-unpaid {
  font-weight: 500;
  color: #af0000;
}

.list_item p {
  margin: 0;
}

.list a {
  display: flex;
  max-height: 100%;
}

.list_item p:last-child {
  max-width: 25%;
  width: 25%;
  max-height: 100%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_item p:first-child {
  text-align: left;
}

.lbl_modal_header {
  display: flex;
}

.list_group {
  padding: 0;
  max-height: 5em;
  width: 100%;
  overflow: auto;
  border: 1px solid #ced4da;
  border-radius: 0.3em;
  padding: 0.2em 0.6em;
  margin: 0;
}

.checkbox_rst,
.cb_pay_hist,
.cb_loc {
  margin-right: 0.3em;
  height: 0.9em;
  align-self: center;
}

.cb_label {
  font-size: 0.8em;
  height: 100%;
  align-self: center;
  vertical-align: middle;
}

.lbl_j_c_spb {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}

.last_pay_date_err {
  color: #bc0000;
}

.lbl_task_status {
  font-size: 1.3em;
  font-weight: bold;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.lbl_task_st_ovr,
.lbl_task_st_pay_late {
  background-color: #c50b0b;
  color: white;
}

.lbl_task_st_duty {
  background-color: #178b00;
  color: white;
}

.lbl_fin_date {
  font-size: 120%;
}

.lbl_ext_yes,
.lbl_ext_no {
  font-size: 1em;
  padding: 0 0.5em 0;
}

.lbl_ext_yes {
  color: #0ab08f;
}

.lbl_ext_no {
  color: #a30000;
}

.tbl_payment,
.tbl_pay_os,
.tbl_pay_expense,
.tbl_modal {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tbl_payment thead tr th,
.tbl_pay_os thead tr th,
.tbl_modal thead tr th {
  text-align: center;
  font-weight: 500;
  font-size: 0.8em;
}

.tbl-detail tbody tr td:nth-child(2) {
  text-align: center;
  width: 20px;
}

.tbl-detail tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

/* .tbl-detail tbody tr td {
  vertical-align: top;
} */

.tbl_pay_expense thead tr th {
  text-align: center;
  font-weight: 500;
  font-size: 1em;
}

.tbl_pay_os thead tr th:first-child {
  width: 1%;
}

.tbl_col_type {
  width: 8em;
  max-width: 8em;
  text-align: center;
  overflow-wrap: break-word;
}

.tbl_thead tr {
  line-height: 2em;
  color: white;
  background: linear-gradient(0deg, rgba(16, 44, 56, 1) 0%, rgba(17, 63, 83, 1) 49%, rgba(0, 81, 116, 1) 100%);
  border-bottom: 1px solid #d7d7d7;
  print-color-adjust: exact;
}

.tbl_payment tbody tr,
.tbl_pay_os tbody tr,
.tbl_modal tbody tr {
  font-size: 0.8em;
  height: 3em;
}

.tbl_pay_expense tbody tr {
  font-size: 1em;
  height: 2.5em;
}

.tbl_pay_expense {
  border-bottom: 1px solid rgb(209, 209, 209);
}


.sign-sec {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-items: flex-end;
}

.sign-box {
  width: 200px;
  height: 80px;
  border: solid 1px #979797;
}

.tbl_payment tbody tr:last-child,
.tbl_modal tbody tr:last-child {
  border-bottom: 1px solid #d7d7d7;
}

.tbl_payment tbody tr td,
.tbl_modal tbody tr td {
  padding-right: 0.3em;
  padding-left: 0.3em;
}

.modal-body {
  padding: 0;
}

.modal-body-1 {
  width: 10em;
}

.modal-v,
.pay-f-sec {
  padding: 16px;
}

.lbl_notes {
  overflow-wrap: break-word;
}

.dtl_payment {
  text-align: center;
  font-size: larger;
  font-weight: 500;
  margin-top: 0.5em;
  color: #888888;
}

.tbl_th_d {
  width: 1.5em;
  line-height: 0.9em;
}

.tbl_body tr:nth-child(even) td {
  background-color: #fafafa !important;

}

.tbl_th_total {
  width: 8em;
  max-width: 8em;
}

.tbl_pay_th_total {
  width: 6em;
  max-width: 6em;
}

.tbl_pay_th_desc {
  width: 16em;
  max-width: 16em;
}

.tbl_th_bank_actions {
  width: 2em;
  max-width: 2em;
}

.tbl_th_type {
  width: 8em;
  max-width: 8em;
}

.tbl_th_rate {
  width: 9em;
  max-width: 9em;
}

.bg_total_pay {
  display: flex;
  font-size: 140%;
  font-weight: bold;
  color: #043842;
  justify-content: flex-end !important;
  margin: 0.5em 0;
}

.lbl_total {
  margin-right: 1em;

}

.lbl_rmng_debt {
  font-size: 1em !important;
  color: rgb(192, 14, 14);
  margin-right: 1em;
}

.lbl_debt-amount {
  color: rgb(192, 14, 14);
}

.checkbox_location {
  vertical-align: middle;
  width: 0.7em;
}

.cb_location {
  vertical-align: middle;
}

.cb_location label {
  font-size: 0.82em;
  vertical-align: middle;
  line-height: 1em;
}

.select_locations {
  max-width: 16em;
}

.btn_change_loc {
  margin-top: 0.4em;
}

.hr-task {
  margin-bottom: 0.7em;
  margin-top: 0.7em;
}

.hr-task-between {
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}

.hr-task-top {
  margin-bottom: 0.7em;
  margin-top: 0.1em;
}

.hr-task-btm {
  margin-bottom: 0.1em;
  margin-top: 0.7em;
}

.ext-sec {
  font-size: 0.9em;
  display: table;
  vertical-align: middle;
  width: 100%;
}

.ext-sec-child {
  line-height: 100%;
  display: table-cell;
  vertical-align: middle;
  align-items: center;
}

.loc_sec {
  border: 1px #e9ecef;
  background-color: #0ab08f;
}

.lbl_ext_date {
  width: 50%;
  text-align: right;
}

.btn {
  border-radius: 0.25rem;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif, "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  list-style: none;
  margin: 0 0.2em;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-primary {
  background-color: #1976D2;
}

.btn:disabled {
  pointer-events: none;
  background-color: #e6ebe3;
  color: #717171;
}

.btn-danger {
  background-color: #bf1e1e;
}

.btn-secondary {
  color: #3a3a3a;
}

.btn-primary:hover {
  background-color: #004e8e;
}

.btn-primary:active {
  background-color: #081e30;
}

.btn-import {
  height: 35px;
  width: 100%;
  margin: 0;
}

.col-3 {
  float: left;
  width: calc(100%/3);
}

.col-2 {
  float: left;
  width: calc(100%/2);
}

.row:after {
  content: "";
  display: table;
  clear: both;

}

.wrap-span,
.wrap-span-bank {
  font-size: 0.8rem;
  overflow-wrap: break-word;
}

.header-2 {
  color: white;
  text-align: center;
  font-weight: 500;
  background-color: var(--N700, rgba(64, 172, 60, 0.96));
  print-color-adjust: exact;
  font-size: 0.9em;
  justify-content: center;
}

.bill-st-paid {
  background-color: rgba(64, 172, 60, 0.96);
}

.bill-st-unpaid {
  background-color: #bf1e1e;
}

.bill-st-paid,
.bill-st-unpaid {
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: bold;
}

.line-bill {
  margin: 0.3em 0;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.modal-os {
  width: 50% !important;
  max-width: 80%;
}

.modal-w-idcard {
  width: 240px !important;
}

.modal-w-40 {
  width: 40% !important;
}

.modal-w-50 {
  width: 50% !important;
}

.modal-w-60 {
  width: 60% !important;
}

.modal-w-70 {
  width: 70% !important;
}

.modal-w-80 {
  width: 80% !important;
}

.modal-w-90 {
  width: 90% !important;
}

[class*="modal-w-"],
.modal-journal,
.modal-add-rcv,
.modal-acc,
.modal-add-wh {
  max-width: 90% !important;
}

.modal-journal {
  width: 70% !important;
}

.modal-add-rcv {
  width: 90% !important;
}

.modal-acc {
  width: 50% !important;
  max-width: 80%;
}

.modal-emp {
  width: 35%;
  max-width: 80%;
}

.modal-dtl_expense,
.modal-inv_expense {
  width: auto;
  max-width: 40%;
}

.modal-pay_expense {
  width: auto;
  max-width: 70%;
}

.desc-value,
.desc-title {
  font-size: 0.9em;
  word-break: break-all;
}

.desc-title,
.title-lbl {
  font-size: 0.9em;
  color: var(--N700, rgba(19, 21, 26, 0.96));
  font-weight: 500;
}

.desc-sec {
  margin-bottom: 1em;
}

.drop-zone {
  font-size: 1.1em !important;
}

.dz-header,
.dz-footer {
  font-size: 0.7em !important;

}

.dz-header span:last-child svg {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.dz-fm div:first-child {
  width: 100% !important;
  height: 7em !important;
}

.dz-fm div:last-child {
  width: 100% !important;
}

.dz-fm {
  width: 7em !important;
  height: 9em !important;
}

.dz-fm div:first-child div:last-child div:first-child {
  height: fit-content !important;
}

.dz-fm div:first-child div:last-child div:last-child div:first-child div:first-child {
  font-size: 2em !important;
}

.dz-fm div:first-child div:last-child div:last-child div:first-child div:last-child {
  font-size: 1.5em !important;
}

.dz-fm div:first-child div:last-child div:last-child div:last-child svg {
  scale: 0.8;
}

.dz-fm div:first-child div:last-child div:first-child svg {
  scale: 0.8;
}

.dz-fm div:last-child {
  font-size: 0.6em !important;
}

.dz-error {
  color: #e41919 !important;
  border-color: #e41919 !important;
}

.dz-normal {
  color: rgba(100, 108, 127, 1) !important;
  border-color: 1px dashed rgba(100, 108, 127, 1) !important;
}

.upload-sec,
.back-date-sec {
  padding: 0 16px;
  margin-bottom: 0.8em;
}

.balance-check-ttl {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: #154969;
  color: white;
  font-weight: 500;
  height: 1.7em;
  font-size: 0.9em;
}

.v-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 0.9em;
  height: 100%;
  min-height: 0;
}

.v-test-ttl {
  font-weight: bold;
}

.check-rs-0,
.check-rs-1 {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 0.9em;
}

.check-rs-0 {
  background-color: #a51111;
}

.check-rs-1 {
  background-color: #19bd2f;
}

.compare-sec {
  background-color: #f5f5f5;
  color: #585858;
  height: 6.5em;
}

.row-bank,
.row-list {
  height: 2.2em !important;
}

.row-payment {
  height: 2.2em !important;
}

.row-bank td,
.row-list td {
  text-align: center;
}

.row-center {
  text-align: center;
}

.td-total,
.td-foot-total {
  text-align: right;
}

.td-foot-total {
  font-size: 1.1rem;
  font-weight: 600;
}

.tbl-real-pay {
  width: 1%;
  text-align: center;
}

.tbl-real-pay tbody tr td:nth-child(2) {
  width: 1%;
}

.tbl-real-pay tbody tr td:nth-child(1) {
  width: 1%;
  white-space: nowrap;
}

.add-cost {
  display: flex;
  justify-content: space-between;

}

.btn-add-list {
  height: 35px;
}

.btn-list-sec {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.btn-add-cost {
  width: 4em;
  margin-left: 0.5em;
}

.btn-add-bank {
  margin: 0.4em 0;
}

.btn-bank-sec {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.progress-bar-mdl {
  margin-top: 4px;
  border-radius: 5px;
  width: 100%;
  /* width: calc(100% - 40px); */
  /* margin: 20px; */
  /* padding: 0.2em; */
  background-color: rgb(228, 228, 228);
  height: 10px;
  position: relative;
}

.progress-bar-mdl div {
  border-radius: 5px;
  background-color: #16af3c;
  height: 10px;
  width: 0%;
}

.progress-bar-mdl span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.5rem;
  font-weight: 600;
  /* margin-top: 5px; */
  font-family: Verdana;
}

.rst_pwd {
  margin-top: 0.3em;
  height: fit-content;
}

.rst_pwd label {
  margin: 0;
}

.tbl_th_num {
  width: 10%;
  max-width: 10%;
}

.tbl_h_h1,
.tbl_v_h1 {
  width: var(--tbl-col1);
  max-width: var(--tbl-col1);
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbl_h_h2,
.tbl_v_h2 {
  width: var(--tbl-col2);
  max-width: var(--tbl-col2);
}

.tbl_h_h3,
.tbl_v_h3 {
  width: var(--tbl-col3);
  max-width: var(--tbl-col3);
}

.tbl_h_h4,
.tbl_v_h4 {
  width: var(--tbl-col4);
  max-width: var(--tbl-col4);
}

.tbl_h_h5,
.tbl_v_h5 {
  width: var(--tbl-col5);
  max-width: var(--tbl-col5);
}

.tbl_h_h6,
.tbl_v_h6 {
  width: var(--tbl-col6);
  max-width: var(--tbl-col6);
}

.tbl_h_h7,
.tbl_v_h7 {
  width: var(--tbl-col7);
  max-width: var(--tbl-col7);
}

.tbl_h_h8,
.tbl_v_h8 {
  width: var(--tbl-col8);
  max-width: var(--tbl-col8);
}

.tbl_h_h9,
.tbl_v_h9 {
  width: var(--tbl-col9);
  max-width: var(--tbl-col9);
}

.tbl_h_h10,
.tbl_v_h10 {
  width: var(--tbl-col10);
  max-width: var(--tbl-col10);
}

.tbl_th_id {
  width: 30%;
  max-width: 30%;
}

.tbl_th_desc {
  width: 50%;
  max-width: 50%;
}

.tbl_th_actions {
  width: 10%;
  max-width: 10%;
}


.idcard-content {
  background-image: url('../../../assets/svg/id-card-f.svg');
  background-repeat: no-repeat;
  width: 204.1px;
  height: 325.033px;
  max-height: 325.033px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.top-spacer-idcard{
  padding-top: 97px;
}

.idcard-crew-state,
.idcard-img,
.idcard-detail {
  font-family: Arial;
  width: 204.1px;
  max-width: 204.1px;
  text-align: center;
  color: black;
}

.idcard-crew-state {
  font-size: 8pt;
  height: 10px;
  font-weight: 600;
}
.idcard-img{
  margin-top: 6px;  
  width: 100%;
  height: 151px;
  max-height: 151px;
  display: flex;
  justify-content: center;
}

.idcard-img img{
  border: none;
  width: 113px;
  max-width: 113px;
}

.idcard-detail{
  top: 288px;
}

.idcard-name {
  margin-top: 6px;
  font-size: 8pt;
  line-height: 8pt;
  font-weight: 600;
}

.idcard-id {
  font-size: 8pt;
  line-height: 8pt;
  font-weight: 600;
}

.idcard-position {
  margin: 2.5pt;
  font-size: 7pt;
  line-height: 7pt;
  font-weight: 600;
}

.idcard-exp {
  font-size: 7pt;
  line-height: 7pt;
}

.btn-p-order{
  margin: 2em 0 1em 0;
}

.btn-container{
  width: 100%;
  display: flex;
  justify-content: center;
}

@media print {
  .print-area {
    /* padding: 1rem;
    margin: 1rem; */
    print-color-adjust: exact !important;
  }
}

@media screen and (max-width: 1024px) {

  /* .modal-emp {
    width: 70%;
    max-width: 70%;
  } */
  [class*="modal-w-"] {
    width: 100% !important;
    max-width: 97% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .modal-dtl_expense {
    width: auto;
    max-width: 80%;
  }

  .modal-pay_expense {
    width: auto;
    max-width: 70%;
  }

  .bg_total_pay {
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="modal-w-"] {
    width: 100%;
    max-width: 97%;
    /* margin: auto !important; */
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .modal-os,
  .modal-emp,
  .modal-acc,
  .modal-journal,
  .modal-add-rcv,
  .modal-dtl_expense,
  .modal-pay_expense,
  .modal-add-wh {
    width: auto;
    max-width: 95%;
  }

  .btn-list-sec {
    padding: 5px 0;
    justify-content: center !important;
  }


}

@media screen and (max-width: 480px) {

  .sub_title {
    font-size: 0.6rem;
  }

  .wrap-span-bank {
    font-size: 0.5rem;
  }

  .wrap-span {
    font-size: 0.6rem;
  }

  .header-2 {
    font-size: 0.5rem;
  }

  .sub_title_bg {
    font-size: 0.45rem;
  }

  .sub_title_payment {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .inv_value {
    font-size: 0.7rem;
  }

  .modal-os,
  .modal-emp,
  .modal-acc,
  .modal-journal,
  .modal-dtl_expense,
  .modal-pay_expense,
  .modal-inv_expense,
  .modal-add-wh {
    width: auto;
    max-width: 95%;
  }
}

@media print {
  .idcard-crew-state {
    top: 72pt;
    /* font-size: 8pt;
    font-weight: 600; */
  }
  .idcard-img{
    top: 84pt;
  }

  .idcard-detail{
    top: 204pt;
  }
  /* .idcard-crew-state {
    top: 70px;
    font-size: 8pt;
    font-weight: 600;
  }
  
  .idcard-name {
    top: 265px;
    font-size: 8pt;
    font-weight: 600;
  }
  
  .idcard-id {
    top: 277px;
    font-size: 8pt;
    font-weight: 600;
  }
  
  .idcard-position {
    top: 289px;
    font-size: 7pt;
    font-weight: 600;
  }
  
  .idcard-exp {
    top: 305px;
    font-size: 7pt;
  } */
}

